import styles from "styles/pages/Login.module.scss";
import logo from "img/cooler.png";
import GoogleAuth from "lib/GoogleAuth";

export default function Login() {
  return (
    <>
      <div className={styles.login}>
        <div id="buttonDiv"></div>
        <div className={styles.login__logo}>
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div className={styles.login__name}>GLACIERE V2</div>
        <div className={styles.login__scanner__text}>
          <div className={styles.login__google__btn}>
            <GoogleAuth />
          </div>
        </div>
      </div>
    </>
  );
}
