import { useState } from "react";

import pickupLogo from "img/icomoon/pickup-truck.png";
import styles from "components/Grids/Cells/PickupRequestCell/PickupRequestCell.module.scss";
import { requestPickup } from "api/GlaciereAPI";
import { notifyInfo } from "lib/ToastNotify";
import { IGridGlaciereWithStatus } from "components/Grids/GridGlacieres/IGridGlacieres";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

export default function PickupRequestCell(props: { data: IGridGlaciereWithStatus }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pickupDate, setPickupDate] = useState<string>("");

  const handleClick = () => {
    setIsLoading(true);
    requestPickup(props.data.glaciere.glaciereId, pickupDate).then((rep) => {
      if (!rep?.error) {
        notifyInfo("Request validated 🙂");

        if (props.data.getGlacieresFunc) {
          props.data.getGlacieresFunc();
        }
      }
      setIsOpen(false);
      setIsLoading(false);
    });
  };

  return (
    <div className={styles.pickupRequest} data-testid="PickupRequestCell-component">
      {(props.data?.glaciere.statusId === 90 || props.data?.glaciere.statusId === 80) && (
        <div>
          {isOpen && !isLoading && (
            <div>
              <input
                type="date"
                onChange={(e) => {
                  setPickupDate(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  handleClick();
                }}
              >
                Send
              </button>
            </div>
          )}
          {isLoading && <LoadingSpinner />}
          {!isLoading && !isOpen && (
            <img
              src={pickupLogo}
              alt="pickup logo"
              onClick={() => {
                setIsOpen(true);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}
