import React from "react";

export default function CustomerCell(props: {
  setCustomerNumber: React.Dispatch<React.SetStateAction<string>>;
  value: string;
}) {
  const handleClick = () => {
    props.setCustomerNumber(props.value);
  };

  return (
    <>
      <div>
        <div
          onClick={() => {
            handleClick();
          }}
        >
          {props.value}
        </div>
      </div>
    </>
  );
}
