import { Link } from "react-router-dom";

export default function LinkCell(props: { value: string }) {
  const path = `/${props.value}`;
  return (
    <div>
      <Link to={path}>{props.value}</Link>
    </div>
  );
}
