import axios, { AxiosResponse } from "axios";
import { localStorageUtils } from "utils/localStorage";

export default function axiosSetup(): void {
  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error): Promise<AxiosResponse<unknown>> => {
      const {
        response: { status },
      } = error;

      if (status === 403 || status === 401) {
        localStorageUtils.removeKey("user");
        window.location.reload();
      }
      return Promise.reject(error);
    },
  );
}
