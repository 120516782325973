/* eslint-disable @typescript-eslint/ban-ts-comment */
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useCallback, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";

import useWindowSize from "hooks/useWindowSize";
import { useStatus } from "hooks/useStatus";

import { IDisplayGridProps } from "./IDisplayGrid";
import styles from "./Grids.module.scss";
import PickupRequestCell from "./Cells/PickupRequestCell/PickupRequestCell";
import PickupCancelCell from "./Cells/PickupCancelCell/PickupCancelCell";
import { IGridGlaciereWithStatus } from "./GridGlacieres/IGridGlacieres";

export default function DisplayGrid({ rowData, columnDefs }: IDisplayGridProps) {
  const status = useStatus();
  const gridRef = useRef();
  const windowSize = useWindowSize();

  const onBtnExport = useCallback(() => {
    if (gridRef != null) {
      // @ts-ignore
      gridRef?.current?.api.exportDataAsCsv();
    }
  }, []);

  const rowClassRules = useMemo(
    () => ({
      // row style function
      warning: () =>
        // TODO not used yet
        true,
    }),
    [],
  );

  return (
    <div className={styles.grid} data-testid="DisplayGrid-component">
      <div className="ag-theme-alpine" style={{ height: windowSize.height }}>
        <button onClick={onBtnExport}>Download CSV export file</button>
        <div className={styles.gridContainer}>
          <AgGridReact
            rowData={rowData}
            pagination={true}
            paginationPageSize={500}
            // @ts-ignore
            ref={gridRef}
            columnDefs={columnDefs}
            rowClassRules={rowClassRules}
            defaultColDef={{
              resizable: true,
            }}
            enableCellTextSelection={true}
            components={{
              PickupRequestCell: (props: { data: IGridGlaciereWithStatus }) => <PickupRequestCell {...props} />,
              PickupCancel: (props: { data: IGridGlaciereWithStatus }) => <PickupCancelCell {...props} />,
            }}
            context={status}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
}
