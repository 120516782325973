import { Link, Outlet } from "react-router-dom";
import { ReactNode } from "react";
import Login from "pages/Login";
import styles from "styles/pages/Home.module.scss";
import logo from "img/cooler.png";
import box from "img/icomoon/SVG/box.svg";
import list from "img/icomoon/SVG/list.svg";
import Popover from "components/Popover/Popover";
import { useStatus } from "hooks/useStatus";
import { localStorageUtils } from "utils/localStorage";
import packageJson from "../../package.json";

export default function Home() {
  const user = localStorageUtils.getUserParsedFromKey();
  const status = useStatus();

  if (!user.loggedIn) {
    return <Login />;
  }

  const displayStatus = (statusList: Map<number, string>) => {
    const items: ReactNode[] = [];

    statusList.forEach((obj) => {
      items.push(<li key={obj}>{obj}</li>);
    });

    return <ul>{items}</ul>;
  };

  return (
    <>
      <div className={styles.home}>
        <div className={styles.menu}>
          <div className={styles.menu__logo}>
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <div className={styles.menu__text}>
            <img src={box} alt="logo glaciere" />
            <Link to="/">Glacieres</Link>
          </div>
          <div className={styles.menu__text}>
            <img src={box} alt="logo glaciere" />
            <Link to="/dashboard">Dashboard</Link>
          </div>
          <div className={styles.menu__text}>
            <img src={list} alt="logo list" />
            <Popover title={"Status"}>{displayStatus(status)}</Popover>
          </div>
          <div className={styles.menu__version}>Version {packageJson.version}</div>
        </div>
        <div className={styles.page}>
          <Outlet />
        </div>
      </div>
    </>
  );
}
