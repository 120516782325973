/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function getEnvVar(varEnv: string) {
  // @ts-ignore
  if (window["getConfig"] != null) {
    // @ts-ignore
    return window["getConfig"][varEnv];
  }
  return process.env[varEnv];
}
