export interface IGlaciere {
  glaciereId: string;
  date: string;
  statusId: number;
  cookit_customer_id?: string;
  order_number?: string;
  pickup_hub?: string;
  pickup_route?: string;
  pickup_date?: string;
  pickup_tracking_code?: string;
  pickup_tentative?: number;
  pickup_arrival_time_from?: string;
  pickup_arrival_time_to?: string;
  pickup_note?: string;
  shipper_hub?: string;
  shipper_route?: string;
  shipment_date?: string;
  ops_shipment_id?: number;
  shipment_tracking_code?: string;
  shipment_arrival_time_to?: string;
  shipment_arrival_time_from?: string;
  shipment_note?: string;
  fulfillment_code?: string;
}

export interface IGlaciereHistory {
  glaciere_id: string;
  statusId: number;
  creation_date: string;
  cookit_customer_id: string;
  order_number: string;
  pickup_hub: string;
  pickup_route: string;
  pickup_date: string;
  pickup_code: string;
  pickup_tracking_code: string;
  pickup_tentative: number;
  pickup_arrival_time_from: string;
  pickup_arrival_time_to: string;
  pickup_note: string;
  shipper_hub: string;
  shipper_route: string;
  shipment_date: string;
  ops_shipment_id: number;
  shipment_tracking_code: string;
  shipment_arrival_time_to: string;
  shipment_arrival_time_from: string;
  shipment_note: string;
  fulfillment_code: string;
}

export interface IGlaciereDashboard {
  status: string;
  count: number;
}

export const enum Pickup {
  AUTOMATIC = 1,
  MANUAL = 2,
}
