import { useState } from "react";
import { requestCancel } from "api/GlaciereAPI";
import { notifyInfo } from "lib/ToastNotify";

import cancelLogo from "img/icomoon/cancel.png";
import styles from "components/Grids/Cells/PickupCancelCell/PickupCancelCell.module.scss";
import { IGridGlaciereWithStatus } from "components/Grids/GridGlacieres/IGridGlacieres";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

export default function PickupCancelCell(props: { data: IGridGlaciereWithStatus }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClick = () => {
    setIsLoading(true);
    requestCancel(props.data.glaciere.glaciereId).then((rep) => {
      if (!rep?.error) {
        notifyInfo("Pickup Cancelled 🙂");

        if (props.data.getGlacieresFunc) {
          props.data.getGlacieresFunc();
        }
      }
      setIsLoading(false);
    });
  };

  return (
    <div data-testid="PickupCancelCell-component">
      {props.data.glaciere.statusId === 110 && !isLoading && (
        <div className={styles.cancelRequest}>
          <img
            src={cancelLogo}
            alt="cancel logo"
            onClick={() => {
              handleClick();
            }}
          />
        </div>
      )}
      {isLoading && <LoadingSpinner />}
    </div>
  );
}
