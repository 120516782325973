import { useEffect, useRef } from "react";
import { localStorageUtils } from "utils/localStorage";

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */

declare let google: any;

export default function GoogleAuth() {
  const googleButton = useRef(null);

  useEffect(() => {
    // @ts-ignore
    const id = window["getConfig"].REACT_APP_GOOGLE_CLIENT_ID;

    if (google) {
      google.accounts.id.initialize({
        client_id: id,
        callback: handleCredentialResponse,
      });

      google.accounts.id.renderButton(googleButton.current, {
        theme: "outline",
        size: "large",
        text: "signin_with",
        logo_alignment: "left",
        shape: "rectangular",
        type: "standard",
        width: 230,
      });
    }
  });

  function handleCredentialResponse(response: any) {
    const userData = JSON.stringify({
      loggedIn: true,
      token: response?.token,
      isDevelopper: false,
    });
    localStorageUtils.setKey("user", userData);
    window.location.reload();
  }

  return (
    <>
      <div ref={googleButton}></div>
    </>
  );
}
