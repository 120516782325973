import { useEffect, useState } from "react";

import { getStatusLabel, useStatus } from "hooks/useStatus";
import DisplayGrid from "components/Grids/DisplayGrid";
import { IGridHistory } from "./IGridHistory";
import { IGridGlaciereWithStatus } from "../GridGlacieres/IGridGlacieres";

export default function GridHistory({ glacieres }: IGridHistory) {
  const status = useStatus();
  const [rowData, setRowData] = useState<IGridGlaciereWithStatus[]>([]);

  useEffect(() => {
    if (glacieres?.length > 0) {
      setRowData([]);

      glacieres.forEach((g) => {
        const elmt: IGridGlaciereWithStatus = {
          glaciere: g,
          status: getStatusLabel(status.get(g.statusId)),
        };
        setRowData((prev) => [...prev, elmt]);
      });
    }
  }, [glacieres, status]);

  const [columnDefs] = useState([
    {
      headerName: "History",
      children: [
        { headerName: "Date", field: "glaciere.date", sortable: true, filter: true },
        { headerName: "Customer id", field: "glaciere.cookit_customer_id" },
        { field: "glaciere.statusId" },
      ],
    },
    {
      headerName: "Shipment",
      openByDefault: false,
      children: [
        { headerName: "Shipment date", field: "glaciere.shipment_date", columnGroupShow: "open" },
        {
          headerName: "Shipment tracking code",
          field: "glaciere.shipment_tracking_code",
          sortable: true,
          filter: true,
          columnGroupShow: "open",
        },
        { headerName: "Shipment note", field: "glaciere.shipment_note", columnGroupShow: "open" },
        { headerName: "Shipper hub", field: "glaciere.shipper_hub", columnGroupShow: "open" },
        { headerName: "Shipper route", field: "glaciere.shipper_route", columnGroupShow: "open" },
      ],
    },
    {
      headerName: "Pickup",
      children: [
        { headerName: "Pickup code", field: "glaciere.pickup_tracking_code", sortable: true, filter: true },
        { headerName: "Pickup date", field: "glaciere.pickup_date", columnGroupShow: "open" },
        { headerName: "Pickup note", field: "glaciere.pickup_note", columnGroupShow: "open" },
        { headerName: "Pickup hub", field: "glaciere.pickup_hub", columnGroupShow: "open" },
        { headerName: "Pickup route", field: "glaciere.pickup_route", columnGroupShow: "open" },
        { headerName: "Pickup tentative", field: "glaciere.pickup_tentative", columnGroupShow: "open" },
      ],
    },
  ]);

  return (
    <>
      <DisplayGrid rowData={rowData} columnDefs={columnDefs} />
    </>
  );
}
