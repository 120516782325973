import { ReactNode } from "react";
import styles from "components/Popover/Popover.module.scss";

interface PopoverProps {
  title: string;
  children: ReactNode;
}

export default function Popover({ title, children }: PopoverProps) {
  return (
    <>
      <div className={styles.popover__wrapper}>
        <a href="#">
          <span className={styles.popover__title}>{title}</span>
        </a>
        <div className={styles.popover__content}>{children}</div>
      </div>
    </>
  );
}
