/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { getStatusKey, getStatusLabel, useStatus } from "hooks/useStatus";
import Swal from "sweetalert2";

import DisplayGrid from "components/Grids/DisplayGrid";
import LinkCell from "components/Grids/Cells/LinkCell/LinkCell";
import CustomerCell from "components/Grids/Cells/CustomerCell/CustomerCell";
import CustomerPopup from "components/Popup/CustomerPopup/CustomerPopup";
import { IGlaciere } from "data/IGlaciere";
import { getAllGlaciere, updateGlaciere } from "api/GlaciereAPI";
import { IGridGlaciereWithStatus } from "./IGridGlacieres";

export default function GridDefinition(props: { statusFilters: string[] }) {
  const statusList = useStatus();
  const [rowData, setRowData] = useState<IGridGlaciereWithStatus[]>([]);
  const [customerNumber, setCustomerNumber] = useState<string>("");
  const [glaciereList, setGlaciereList] = useState<IGlaciere[]>([]);

  const getGlacieres = () => {
    getAllGlaciere().then((result) => {
      if (!result?.error) {
        setGlaciereList(result);
      }
    });
  };

  useEffect(() => {
    getGlacieres();
  }, []);

  useEffect(() => {
    if (glaciereList?.length > 0) {
      setRowData([]);

      glaciereList.forEach((g) => {
        const elmt: IGridGlaciereWithStatus = {
          glaciere: g,
          status: getStatusLabel(statusList.get(g.statusId)),
          getGlacieresFunc: getGlacieres,
        };
        setRowData((prev) => [...prev, elmt]);
      });
    }
  }, [glaciereList, statusList]);

  const changeStatus = (params: { data: { glaciere: IGlaciere }; newValue: string; context: any }) => {
    // You have to store it here because otherwise it seems to be resetted to actual value in then section later
    const newStatus = params.newValue;

    Swal.fire({
      title: `Are you sure you want to change the status to ${newStatus}`,
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (!result.isConfirmed) return;
      // Get the number corresponding to the status we changed
      const statusToUpdate = getStatusKey(newStatus, params.context);

      // If status exists
      if (statusToUpdate === undefined || !params.data.glaciere) return;

      params.data.glaciere.statusId = statusToUpdate;

      // Update glaciere with corresponding status
      updateGlaciere(params.data.glaciere).then(() => {
        // Refresh glaciere list
        getGlacieres();
      });
    });
  };

  const [columnDefs] = useState([
    {
      headerName: "Glacieres",
      children: [
        {
          headerName: "Id",
          field: "glaciere.glaciereId",
          cellRenderer: LinkCell,
          sortable: true,
          filter: true,
        },
        { headerName: "Last update", field: "glaciere.date", sortable: true, filter: true },
        { headerName: "Customer id", field: "glaciere.cookit_customer_id", sortable: true, filter: true },
        {
          headerName: "Order number",
          field: "glaciere.order_number",
          cellRenderer: CustomerCell,
          cellRendererParams: { setCustomerNumber: setCustomerNumber },
          sortable: true,
          filter: true,
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          filter: true,
          editable: true,
          cellEditor: "agSelectCellEditor",
          valueSetter: changeStatus,
          cellEditorParams: {
            values: props.statusFilters,
          },
        },
      ],
    },
    {
      headerName: "Shipment",
      openByDefault: false,
      children: [
        {
          headerName: "Shipment tracking code",
          field: "glaciere.shipment_tracking_code",
          sortable: true,
          filter: true,
          columnGroupShow: "open",
        },
        { headerName: "Shipment note", field: "glaciere.shipment_note", columnGroupShow: "open" },
        { headerName: "Shipment hub", field: "glaciere.shipment_hub", columnGroupShow: "open" },
        { headerName: "Shipment route", field: "glaciere.shipment_route", columnGroupShow: "open" },
        {
          headerName: "Shipment estimated date",
          field: "glaciere.shipment_date",
          columnGroupShow: "open",
          sortable: true,
          filter: true,
        },
      ],
    },
    {
      headerName: "Pickup",
      children: [
        { headerName: "Pickup tracking", field: "glaciere.pickup_tracking_code", sortable: true, filter: true },
        {
          field: ".",
          headerName: "Request pickup",
          cellRenderer: "PickupRequestCell",
        },
        {
          field: ".",
          headerName: "Cancel pickup",
          cellRenderer: "PickupCancel",
        },
        {
          headerName: "Pickup date",
          field: "glaciere.pickup_date",
          columnGroupShow: "open",
          sortable: true,
          filter: true,
        },
        { headerName: "Pickup note", field: "glaciere.pickup_note", columnGroupShow: "open" },
        { headerName: "Pickup hub", field: "glaciere.pickup_hub", columnGroupShow: "open" },
        { headerName: "Pickup route", field: "glaciere.pickup_route", columnGroupShow: "open" },
        {
          headerName: "Pickup tentative",
          field: "glaciere.pickup_tentative",
          columnGroupShow: "open",
          sortable: true,
          filter: true,
        },
      ],
    },
  ]);

  return (
    <>
      <DisplayGrid rowData={rowData} columnDefs={columnDefs} getGlacieres={getGlacieres} />
      <CustomerPopup customerNumber={customerNumber} />
    </>
  );
}
