import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getHistoryById } from "api/GlaciereAPI";
import { IGlaciere, IGlaciereHistory } from "data/IGlaciere";
import styles from "styles/pages/History.module.scss";
import GridHistory from "components/Grids/GridHistory/GridHistory";

export default function History() {
  const { id } = useParams();
  const [glaciereList, setGlaciereList] = useState<IGlaciere[]>([]);

  useEffect(() => {
    if (id) {
      getHistoryById(id).then((rep) => {
        setGlaciereList([]);

        if (!rep?.error) {
          rep.forEach((g: IGlaciereHistory) => {
            const elmt: IGlaciere = {
              ...g,
              glaciereId: g.glaciere_id,
              date: g.creation_date,
            };
            setGlaciereList((prev) => [...prev, elmt]);
          });
        }
      });
    }
  }, [id]);

  return (
    <>
      <div className={styles.history__title}>{id}</div>
      <GridHistory glacieres={glaciereList} />
    </>
  );
}
