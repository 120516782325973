import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import "styles/global.scss";
import Login from "pages/Login";
import GridGlacieres from "components/Grids/GridGlacieres/GridGlacieres";
import History from "pages/History";
import Home from "pages/Home";
import Dashboard from "pages/Dashboard";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<Home />}>
          <Route path="/" element={<GridGlacieres />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/:id" element={<History />} />
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
