import { useEffect, useState } from "react";
import { getCustomerByNumber } from "api/GlaciereAPI";
import styles from "components/Popup/CustomerPopup/CustomerPopup.module.scss";

export default function CustomerPopup(props: { customerNumber: string }) {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (props.customerNumber !== "") {
      getCustomerByNumber(props.customerNumber).then((result) => {
        if (!result?.error) {
          setData({
            firstName: result.firstName,
            lastName: result.lastName,
            email: result.email,
          });
          setIsOpen(true);
        }
      });
    }
  }, [props.customerNumber]);

  const handleClick = () => {
    setIsOpen(false);
    setData({
      firstName: "",
      lastName: "",
      email: "",
    });
  };

  return (
    <>
      {isOpen && (
        <div className={styles.customerPopup}>
          <div
            className={styles.customerPopup__close}
            onClick={() => {
              handleClick();
            }}
          >
            x
          </div>
          <div>first_name: {data.firstName}</div>
          <div>last_name: {data.lastName}</div>
          <div>email: {data.email}</div>
        </div>
      )}
    </>
  );
}
