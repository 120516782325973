/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from "axios";
import { IGlaciere, Pickup } from "data/IGlaciere";
import { getEnvVar } from "lib/GetEnvVar";
import { notifyError } from "lib/ToastNotify";

// @ts-ignore
const BASE_URL = getEnvVar("REACT_APP_BACK_URL");

export async function getAllGlaciere() {
  try {
    const resp = await axios.get(`${BASE_URL}/api/glacieres`);

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function getGlaciereById(glaciereId: string) {
  try {
    const resp = await axios.post(
      `${BASE_URL}/api/glaciere/researches`,
      JSON.stringify({
        glaciereId: glaciereId,
      }),
    );

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function getHistoryById(glaciereId: string) {
  try {
    const resp = await axios.get(`${BASE_URL}/api/history/${glaciereId}`);

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function getStatus() {
  try {
    const resp = await axios.get(`${BASE_URL}/api/glaciere/status`);

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function getCountByStatus() {
  try {
    const resp = await axios.get(`${BASE_URL}/api/glaciere/count`);

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function getCustomerByNumber(number: string) {
  try {
    const resp = await axios.get(`${BASE_URL}/api/customer/${number}`);

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function requestPickup(glaciereId: string, pickupDate: string) {
  try {
    const resp = await axios.put(
      `${BASE_URL}/api/glaciere/requestPickup/${glaciereId}`,
      JSON.stringify({
        pickup_date: pickupDate,
        pickup_type: Pickup.MANUAL,
      }),
    );

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function requestCancel(glaciereId: string) {
  try {
    const resp = await axios.put(
      `${BASE_URL}/api/glaciere/cancelPickup`,
      JSON.stringify({
        glaciereId: glaciereId,
      }),
    );

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function updateGlaciere(glaciere: IGlaciere) {
  try {
    const resp = await axios.put(`${BASE_URL}/api/glaciere`, JSON.stringify(glaciere));

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

async function handleResponse(resp: any) {
  switch (resp.status) {
    case 200: {
      return resp.data;
    }
    case 201: {
      return { message: "done" };
    }
    default: {
      notifyError(resp.statusText);
      return { error: resp.status };
    }
  }
}
