/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

import DisplayGrid from "components/Grids/DisplayGrid";
import { IGlaciereDashboard } from "data/IGlaciere";
import { getStatusLabel, useStatus } from "hooks/useStatus";

export default function GridDashboard(props: { statusData: IGlaciereDashboard[] }) {
  const [rowData, setRowData] = useState<IGlaciereDashboard[]>([]);
  const statusList = useStatus();

  useEffect(() => {
    if (props.statusData?.length > 0) {
      setRowData([]);

      props.statusData.forEach((g: any) => {
        const elmt: IGlaciereDashboard = {
          count: g.Count,
          status: getStatusLabel(statusList.get(g.Status)),
        };

        setRowData((prev) => [...prev, elmt]);
      });
    }
  }, [statusList, props.statusData]);

  const [columnDefs] = useState([
    {
      headerName: "Count of glacieres by status",
      children: [
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Count",
          field: "count",
          sortable: true,
          filter: true,
        },
      ],
    },
  ]);

  return (
    <div data-testid="GridDashboard-component">
      <DisplayGrid rowData={rowData} columnDefs={columnDefs} />
    </div>
  );
}
